<div class="modal-header">
  <h5 class="modal-title">Add address</h5>
  <button
    type="button"
    class="close btn"
    aria-label="Close"
    (click)="activeModal.close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form class="modal-body" [formGroup]="addressForm">
  <div class="d-flex flex-column">
    <ng-container>
      <div class="w-100 mb-2">
        <b class="user-select-none">Phone number</b>
      </div>
      <div class="w-100 me-2 mb-2 user-select-none">
        <div class="input-group has-validation">
          <input
            phoneMask
            maxlength="14"
            type="tel"
            autocomplete="off"
            [class.is-invalid]="phone!.touched && phone!.invalid"
            [class.is-valid]="phone!.touched && phone!.valid"
            formControlName="phone"
            class="form-control"
            placeholder="phone number"
            aria-label="phone"
            aria-describedby="phone"
          />
        </div>
      </div>
      <div *ngIf="phone!.dirty && phone!.errors as errors" class="w-100 fw-500">
        <span class="text-danger user-select-none" *ngIf="errors?.['required']"
          >Phone is required</span
        >
        <span class="text-danger user-select-none" *ngIf="errors?.['minlength']"
          >Please input valid phone format</span
        >
      </div></ng-container
    >

    <ng-container>
      <div class="w-100 mb-2">
        <b class="user-select-none">Street</b>
      </div>
      <div class="w-100 me-2 user-select-none">
        <div class="input-group has-validation">
          <input
            type="text"
            autocomplete="off"
            [class.is-invalid]="street!.touched && street!.invalid"
            [class.is-valid]="street!.touched && street!.valid"
            formControlName="address"
            class="form-control"
            placeholder="street"
            aria-label="street"
            aria-describedby="street"
          />
        </div>
      </div>
      <div *ngIf="street!.dirty && street!.errors as errors" class="w-100">
        <span class="text-danger user-select-none" *ngIf="errors?.['required']"
          >Street is required</span
        >
        <span class="text-danger user-select-none" *ngIf="errors?.['minlength']"
          >Please input at least 2 characters</span
        >
      </div></ng-container
    >
    <ng-container>
      <div class="w-100 mb-2 mt-2">
        <b class="user-select-none">Optional</b>
      </div>
      <div class="w-100 me-2 user-select-none">
        <div class="input-group has-validation">
          <input
            type="text"
            autocomplete="off"
            [class.is-invalid]="optional!.touched && optional!.invalid"
            [class.is-valid]="optional!.touched && optional!.valid"
            formControlName="optional"
            class="form-control"
            placeholder="optional"
            aria-label="optional"
            aria-describedby="optional"
          />
        </div>
      </div>
      <div *ngIf="optional!.dirty && optional!.errors as errors" class="w-100">
        <span class="text-danger user-select-none" *ngIf="errors['required']"
          >Optional is required</span
        >
        <span class="text-danger user-select-none" *ngIf="errors['required']"
          >Please input at least 2 characters</span
        >
      </div>
    </ng-container>
    <ng-container>
      <div class="w-100">
        <div class="w-100 mb-2 mt-2">
          <b class="user-select-none">City</b>
        </div>
        <div class="w-100 me-2 user-select-none">
          <div class="input-group has-validation">
            <input
              type="text"
              autocomplete="off"
              [class.is-invalid]="city!.touched && city!.invalid"
              [class.is-valid]="city!.touched && city!.valid"
              formControlName="city"
              name="city"
              class="form-control"
              placeholder="city"
              aria-label="city"
              aria-describedby="city"
            />
          </div>
        </div>
        <div *ngIf="city!.dirty && city!.errors as errors" class="col-12">
          <span
            class="text-danger user-select-none"
            *ngIf="errors?.['required']"
            >City is required</span
          >
          <span
            class="text-danger user-select-none"
            *ngIf="errors?.['minlength']"
            >Please input at least 2 characters</span
          >
        </div>
      </div>
      <div class="d-inline-flex w-100">
        <div class="w-50 pe-1">
          <div class="col-12 mb-2 mt-2">
            <b class="user-select-none">State</b>
          </div>
          <div class="col-12 me-2 user-select-none">
            <div class="input-group has-validation">
              <input
                type="text"
                autocomplete="off"
                [class.is-invalid]="state!.touched && state!.invalid"
                [class.is-valid]="state!.touched && state!.valid"
                formControlName="state"
                name="state"
                class="form-control"
                placeholder="state"
                aria-label="state"
                aria-describedby="state"
              />
            </div>
          </div>
          <div *ngIf="state!.dirty && state!.errors as errors" class="col-12">
            <span
              class="text-danger user-select-none"
              *ngIf="errors?.['required']"
              >State is required</span
            >
            <span
              class="text-danger user-select-none"
              *ngIf="errors?.['minlength']"
              >Please input at least 2 characters</span
            >
          </div>
        </div>
        <div class="w-50 ps-1">
          <div class="mb-2 mt-2">
            <b class="user-select-none">Zipcode</b>
          </div>
          <div class="col-12 me-2 user-select-none">
            <div class="input-group has-validation">
              <input
                type="text"
                autocomplete="off"
                [class.is-invalid]="zipcode!.touched && zipcode!.invalid"
                [class.is-valid]="zipcode!.touched && zipcode!.valid"
                formControlName="zipcode"
                name="zipcode"
                class="form-control"
                placeholder="zipcode"
                aria-label="zipcode"
                aria-describedby="zipcode"
              />
            </div>
          </div>
          <div
            *ngIf="zipcode!.dirty && zipcode!.errors as errors"
            class="col-12"
          >
            <span
              class="text-danger user-select-none"
              *ngIf="errors?.['required']"
              >Zipcode is required</span
            >
            <span
              class="text-danger user-select-none"
              *ngIf="errors?.['minlength']"
              >Please input at least 5 characters</span
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- <div class="d-flex flex-column mt-3" *ngIf="geoData">
      <div
        class="form-check"
        *ngFor="let result of geoData.results; let i = index"
      >
        <input
          (click)="onAddressClick($event, result)"
          class="form-check-input"
          type="radio"
          name="geoResult"
          id="{{ 'geoResult' + i }}"
        />
        <label class="form-check-label" for="{{ 'geoResult' + i }}">
          {{ result.formatted_address }}
        </label>
      </div>
    </div> -->
  <!-- <div class="d-inline-flex mt-3">
      <button
        type="submit"
        class="btn btn-primary me-2"
        [disabled]="!street?.valid || loading"
        (click)="checkAddress()"
      >
        <span *ngIf="!loading; else loadingTemplate" class="user-select-none"
          >Check Address
        </span>
      </button>
    </div> -->
</form>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close(false)"
  >
    Close
  </button>
  <!-- || !addressChecked -->
  <button
    type="button"
    class="btn btn-danger"
    [disabled]="!addressForm.valid || loading"
    (click)="onAddressSubmit()"
  >
    <div *ngIf="!loading; else loadingTemplate" class="user-select-none">
      Add
    </div>
  </button>
</div>
<ng-template #loadingTemplate>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
</ng-template>
