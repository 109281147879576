<div class="wrap">
  <uo-simple-header></uo-simple-header>
  <div class="fill-up"></div>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center pt-4 pb-4 fs-5 fw-bold">
        Reset Password
      </div>
    </div>
    <div class="row mb-2">
      <ng-container *ngIf="isSend; else initTemplate">
        <div
          class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 user-select-none"
        >
          Check {{ email?.value }} for an email to reset your password. You’ll
          only receive an email if there is an associated uOrder account.
        </div>
      </ng-container>
      <ng-template #initTemplate>
        <div
          class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 user-select-none"
        >
          Please specify your email address to receive instructions for
          resetting it. If an account exists by that email, we will send a
          password reset link to your email.
        </div>
      </ng-template>
    </div>
    <div class="row" [formGroup]="forgetPasswordForm">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
        <b class="user-select-none">Email</b>
      </div>
      <div
        class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 user-select-none"
      >
        <div class="input-group">
          <input
            type="email"
            autocomplete="email"
            [class.is-invalid]="email!.touched && email!.invalid"
            [class.is-valid]="email!.touched && email!.valid"
            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
            formControlName="email"
            class="form-control"
            placeholder="email"
            aria-label="email"
            aria-describedby="email"
          />
        </div>
      </div>
      <div
        *ngIf="email!.dirty && email!.errors as errors"
        class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-danger"
      >
        <span class="text-danger user-select-none" *ngIf="errors['required']"
          >Email is required</span
        >
        <span
          class="text-danger user-select-none"
          *ngIf="errors['email'] || errors['pattern']"
          >Please input valid email address</span
        >
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-2">
        <button
          class="btn btn-danger w-100 fs-6 fw-500"
          [disabled]="loading || forgetPasswordForm?.invalid"
          (click)="onSubmit()"
        >
          <span
            *ngIf="!loading; else loadingTemplate"
            class="user-select-none text-nowrap"
          >
            <ng-container *ngIf="isSend; else sendTemplate">
              Resend
            </ng-container>
            <ng-template #sendTemplate> Send </ng-template>
          </span>
          <ng-template #loadingTemplate>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </ng-template>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-2">
        <a routerLink="/sign-in" class="btn btn-outline-danger w-100 fw-500">
          Return to Sign In
        </a>
      </div>
    </div>
  </div>
  <uo-footer class="fixed-bottom"></uo-footer>
</div>
