<div class="wrap">
  <uo-simple-header></uo-simple-header>
  <div class="fill-up"></div>
  <form class="container" [formGroup]="resetPasswordForm">
    <div class="row">
      <div class="col-12 text-center pt-4 pb-4 fs-5 fw-bold">
        Forgot Password
      </div>
    </div>
    <div class="row mt-2">
      <div
        class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 user-select-none fw-bold"
      >
        Password
      </div>
      <div
        class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 user-select-none"
      >
        <div class="input-group">
          <input
            autocomplete="current-password"
            [type]="passwordToggle ? 'password' : 'text'"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d-=_~`+!@#$^%&*()]{8,}$"
            [class.is-invalid]="password!.touched && password!.invalid"
            [class.is-valid]="password!.touched && password!.valid"
            formControlName="password"
            class="form-control rounded-0 rounded-start"
            placeholder="password"
            aria-label="password"
            aria-describedby="password"
          />
          <button
            class="btn btn-outline-secondary"
            (click)="passwordToggle = !passwordToggle"
            type="button"
          >
            <p *ngIf="passwordToggle">Show</p>
            <p *ngIf="!passwordToggle">Hide</p>
          </button>
        </div>
      </div>
      <div
        *ngIf="password!.dirty && password!.errors as errors"
        class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-danger"
      >
        <span class="text-danger user-select-none" *ngIf="errors['required']"
          >Password is required</span
        >
        <span class="text-danger user-select-none" *ngIf="errors['minlength']"
          >Please input at least 8 characters</span
        >
        <div class="text-danger user-select-none" *ngIf="errors['pattern']">
          Must contain at least one uppercase letter, one lowercase letter and
          one number
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div
        class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 user-select-none fw-bold"
      >
        Confirm Password
      </div>
      <div
        class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 user-select-none"
      >
        <div class="input-group">
          <input
            autocomplete="current-password"
            [type]="confirmPasswordToggle ? 'password' : 'text'"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d-=_~`+!@#$^%&*()]{8,}$"
            [class.is-invalid]="
              confirm_password!.touched && confirm_password!.invalid
            "
            [class.is-valid]="
              confirm_password!.touched && confirm_password!.valid
            "
            formControlName="confirm_password"
            class="form-control rounded-0 rounded-start"
            placeholder="confirm password"
            aria-label="confirm_password"
            aria-describedby="confirm_password"
          />
          <button
            class="btn btn-outline-secondary"
            (click)="confirmPasswordToggle = !confirmPasswordToggle"
            type="button"
          >
            <p *ngIf="confirmPasswordToggle">Show</p>
            <p *ngIf="!confirmPasswordToggle">Hide</p>
          </button>
        </div>
      </div>
      <div
        *ngIf="confirm_password!.dirty && confirm_password!.errors as errors"
        class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-danger"
      >
        <span class="text-danger user-select-none" *ngIf="errors['required']"
          >Password is required</span
        >
        <span class="text-danger user-select-none" *ngIf="errors['minlength']"
          >Please input at least 8 characters</span
        >
        <div class="text-danger user-select-none" *ngIf="errors['pattern']">
          Must contain at least one uppercase letter, one lowercase letter and
          one number
        </div>
        <div
          class="text-danger user-select-none"
          *ngIf="errors['confirmedValidator']"
        >
          Password must match confirmed password
        </div>
      </div>
      <div
        *ngIf="resetPasswordForm.errors as errors"
        class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-danger"
      >
        <div
          class="text-danger user-select-none"
          *ngIf="errors['confirmedValidator']"
        >
          Password must match confirmed password
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-2">
        <button
          class="btn btn-danger w-100 fs-6 fw-500"
          [disabled]="loading || resetPasswordForm.invalid"
          (click)="resetPassword()"
        >
          <span
            *ngIf="!loading; else loadingTemplate"
            class="user-select-none text-nowrap"
          >
            Reset Password
          </span>
          <ng-template #loadingTemplate>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </ng-template>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-2">
        <a routerLink="/sign-in" class="btn btn-outline-danger w-100 fw-500">
          Return to Sign In
        </a>
      </div>
    </div>
  </form>
  <uo-footer class="fixed-bottom"></uo-footer>
</div>
