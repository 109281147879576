<div #menuDiv [ngClass]="{ 'sticky-style': onScroll() }">
  <nav class="container-xl pt-2 pb-2 border-bottom">
    <!-- first row -->
    <div class="row mt-2">
      <div class="col-12 d-inline-flex gx-0">
        <!-- menu group select -->
        <div
          class="d-inline-flex align-items-center overflow-scroll categories pt-1 pb-1"
        >
          <div
            class="ms-2 me-2"
            *ngFor="let menuGroup of menuGroups! | sortBySort"
          >
            <button
              type="button"
              [ngClass]="{
                'text-danger': menuGroup['id'] == selectedMenuGroup['id']
              }"
              class="btn btn-sm border rounded fw-bold text-nowrap"
              (click)="
                changeGroup(menuGroup);
                $event.preventDefault();
                $event.stopPropagation()
              "
            >
              {{ menuGroup?.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- second row -->
    <div class="row">
      <div class="col-12 d-inline-flex gx-0 menu-nav">
        <div
          class="d-flex align-items-center"
          *ngIf="categoryView.nativeElement.scrollLeft > 26"
        >
          <button
            class="btn categories-button p-1"
            (click)="
              onCategoryLeft();
              $event.preventDefault();
              $event.stopPropagation()
            "
          >
            <i class="bi bi-caret-left"></i>
          </button>
        </div>
        <div class="categories d-inline-flex ps-2 pe-2" #categoriesScrollBar>
          <div
            [id]
            class="category-item d-flex align-items-center justify-content-center ms-1 me-1"
            *ngFor="let category of categories | sortBySort"
          >
            <ng-container *ngIf="categoryShow(category)">
              <button
                [disabled]=""
                [ngClass]="{
                  'text-danger': this.selectedCategoryId == category.id
                }"
                class="btn category-item-button p-1 nowrap user-select-none border-0"
                (click)="
                  onCategory(category);
                  $event.preventDefault();
                  $event.stopPropagation()
                "
              >
                {{ category.name }}
              </button>
            </ng-container>
          </div>
        </div>
        <div class="d-flex align-items-center" *ngIf="rightBtn()">
          <button
            class="btn categories-button p-1"
            (click)="
              onCategoryRight();
              $event.preventDefault();
              $event.stopPropagation()
            "
          >
            <i class="bi bi-caret-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row fs-4 fw-bold p-4" *ngIf="!menuGroups?.length">
      No menu groups available for now
    </div>
    <div class="row fs-5 fw-bold p-4" *ngIf="!categories?.length">
      No categories available for now
    </div>
  </nav>
</div>
<div class="container-xl">
  <div
    class="list-wrap"
    *ngFor="let category of categories"
    [id]="category.id"
    #categories
  >
    <ng-container *ngIf="categoryShow(category)">
      <div class="row pt-3">
        <div class="col-12 user-select-none fs-4 fw-bold">
          {{ category.name }}
        </div>
        <div
          class="col-12 fs-6 fw-500 user-select-none"
          *ngIf="category.description"
        >
          {{ category.description }}
        </div>
      </div>
      <div class="menuItem row pt-2" *ngIf="category.menu_list">
        <div
          class="col-12 col-sm-6 col-xl-4 mb-3"
          *ngFor="let item of category.menu_list | sortBySort"
        >
          <uo-u-items
            [menuItem]="item"
            [categoryDiningTimes]="category.dining_times"
          ></uo-u-items>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row fs-6 fw-500 text-secondary text-center pt-5 pb-5">
    <div class="col-12">
      Prices on this menu are set directly by the Merchant.
    </div>
    <div class="col-12">Prices may differ between Delivery and Pickup.</div>
  </div>
</div>
