<div class="modal-header">
  <h4 class="modal-title">What are you interested in?</h4>
  <button
    (click)="activeModal.dismiss(false)"
    type="button"
    class="btn-close user-select-none"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <div *ngFor="let item of menu['categories']" class="row">
    <div style="font-size: 18px;font-weight: 600;" *ngIf="item['menu_list'].length > 0" class="col-12">
      {{ item["name"] }}
    </div>
    <div class="form-check mt-2 col-6" *ngFor="let o of item['menu_list']; index as i">
      <input
        style="margin-left: 0rem"
        class="form-check-input col-2"
        type="checkbox"
        [checked] = "o['checked']"
        [id]="o['name']"
        (click)="checkcheckbox(o['name'])"
      />
      <label
        class="form-check-label col-10"
        style="margin-left: 0.2rem"
        [for]="o['name']"
      >
        {{ o["name"] }}
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.close(false)"
    >
      Close
    </button>
    <button
      class="btn btn-danger"
      type="submit"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
  <ng-template #loadingTemplate>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
  </ng-template>
</div>
