<uo-checkout-header></uo-checkout-header>
<!-- spinner -->
<div class="fixed-top clearfix" *ngIf="loading">
  <div class="spinner-wrap fixed-top opacity-50">
    <div class="spinner-border spinner position-fixed" role="status"></div>
  </div>
</div>
<div
  class="mt-35r container-fluid"
  #content
  [ngStyle]="{
    'margin-bottom.px': placeOrderBoxHeight
  }"
>
  <form class="row min-vh-100 position-relative" [formGroup]="placeOrderForm">
    <ng-container *ngIf="feeLoading">
      <div class="fixed-top clearfix d-none d-lg-block">
        <div class="fixed-receipt col-lg-3 z-1099">
          <div
            *ngIf="!loading"
            class="spinner-border spinner position-absolute"
            role="status"
          ></div>
        </div>
      </div>
    </ng-container>
    <!-- left half -->
    <div
      [ngClass]="{ 'opacity-25': feeLoading || loading }"
      class="col-12 col-lg-3 d-none d-lg-block bg-yellow ps-xl-4 pe-xl-4 float-start fixed-receipt"
    >
      <!-- header  -->
      <div class="d-flex mt-2 mt-lg-4">
        <img [src]="checkout?.store?.logo" class="pic rounded border" />
        <!-- <div class="pic"></div> -->
        <div class="d-flex justify-content-center flex-fill flex-column ps-2">
          <div class="text-secondary fw-bolder fs-7 user-select-none">
            ORDER FROM
          </div>
          <div class="fs-5 text-dark fw-bolder user-select-none">
            {{ checkout?.store?.store_name }}
          </div>
          <div class="text-dark fw-bold user-select-none">
            {{ checkout?.store?.phone! }}
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="isCheckout">
        <div class="w-100">
          <div
            class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
          >
            Your items expired!
          </div>
        </div>
      </div>
      <!-- save promotion warining -->
      <div class="row mt-2" *ngFor="let coupon of checkout?.coupons">
        <div class="w-100">
          <div
            class="alert-warning rounded shadow-sm text-center fw-500 p-2 user-select-none"
          >
            Your saved money with {{ coupon.name }} code
          </div>
        </div>
      </div>

      <!-- pickup warning  -->
      <div class="row mt-2" *ngIf="order_type?.value == '2'">
        <div class="w-100">
          <div
            class="alert-warning rounded shadow-sm text-center fw-500 p-2 user-select-none"
          >
            This is a pickup order
          </div>
        </div>
      </div>
      <!-- closure danger  -->
      <div class="row mt-2" *ngIf="checkout?.store?.closure">
        <div class="w-100">
          <div
            class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
          >
            Store closed!
          </div>
        </div>
      </div>
      <!-- address warning  -->
      <ng-container *ngIf="order_type?.value == 1">
        <div class="row mt-2">
          <div class="w-100">
            <div
              class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
              *ngIf="delivery_address?.errors?.['required']"
            >
              Addresss information missing!
            </div>
          </div>
        </div>
        <ng-container *ngIf="minimumDeliveryCompare()">
          <div class="row mt-2">
            <div class="w-100">
              <div
                class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
              >
                Miminum delivery price is ${{ minimumPay[1] }}. Please add more
                item to pay!
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="order_type?.value == 2">
        <ng-container *ngIf="minimumPickupCompare()">
          <div class="row mt-2">
            <div class="w-100">
              <div
                class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
              >
                Miminum pickup price is ${{ minimumPay[2] }}. Please add more
                item to pay!
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="row mt-2">
        <div class="w-100">
          <div
            class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
            *ngIf="phone_number?.errors?.['required']"
          >
            Phone number required!
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="payment_type?.value == 1">
        <div class="w-100">
          <div
            class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
            *ngIf="card_number?.errors?.['required'] || cardName?.errors?.['required']|| expiration_date?.errors?.['required'] || zipcode?.errors?.['required'] || cvv?.errors?.['required']"
          >
            Credit card information missing!
          </div>
        </div>
      </div>
      <!-- count  -->
      <ng-container>
        <div class="w-100 pt-3 pb-3 border-bottom fw-500">
          <div class="d-inline-flex w-100 pb-2">
            <div class="user-select-none fs-6 fw-500">Subtotal</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.subtotal | number: "1.2-2" }}
            </div>
          </div>
          <div
            class="d-inline-flex w-100 pb-1"
            *ngIf="order_type?.value == '1'"
          >
            <div class="user-select-none fs-6 fw-500">Delivery Fee</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.deliveryFees | number: "1.2-2" }}
            </div>
          </div>
          <div
            class="d-inline-flex w-100 pb-1"
            *ngIf="checkout?.coupons?.length"
          >
            <div class="user-select-none fs-6 fw-500">Coupon</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              $-{{ checkout?.couponsAmount | number: "1.2-2" }}
            </div>
          </div>
          <div class="d-inline-flex w-100 align-items-center">
            <div
              class="user-select-none fs-6 fw-500 d-inline-flex align-items-center"
            >
              Estimated Tax
              <button
                type="button"
                class="btn p-1"
                placement="bottom"
                triggers="mouseenter:mouseleave"
                [ngbPopover]="taxPopContent"
                #tax="ngbPopover"
                (click)="tax.open()"
              >
                <i class="ms-75 bi bi-info-circle"></i>
              </button>
            </div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.tax | number: "1.2-2" }}
            </div>
          </div>
          <div class="d-inline-flex w-100 align-items-center">
            <div
              class="user-select-none fs-6 fw-500 d-inline-flex align-items-center"
            >
              Services Fee
              <button
                type="button"
                class="btn p-1"
                placement="bottom"
                triggers="mouseenter:mouseleave"
                [ngbPopover]="otherFeesPopContent"
                #other="ngbPopover"
                (click)="other.open()"
              >
                <i class="ms-75 bi bi-info-circle"></i>
              </button>
            </div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.fees | number: "1.2-2" }}
            </div>
          </div>
        </div>
        <div class="w-100 pt-3 pb-3 border-bottom fw-500">
          <div class="d-inline-flex w-100">
            <div class="user-select-none fs-6 fw-500">Tip</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              <ng-container
                *ngIf="
                  checkout?.tips && checkout?.tips !== '0.00';
                  else noTipsTemplate
                "
              >
                ${{ checkout?.tips | number: "1.2-2" }}
              </ng-container>
              <ng-template #noTipsTemplate> No tips </ng-template>
            </div>
          </div>
        </div>
        <div class="w-100 pt-3 pb-3 border-bottom fw-500">
          <div class="d-inline-flex w-100">
            <div class="user-select-none fs-6 fw-500">Total</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.total | number: "1.2-2" }}
            </div>
          </div>
        </div>
        <!-- <div class="w-100 pt-3 pb-3 border-bottom fw-bold">
          <div class="d-inline-flex w-100">
            <div class="user-select-none fs-6">Amount Due</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.total | number: "1.2-2" }}
            </div>
          </div>
        </div> -->
      </ng-container>
    </div>
    <div class="col-12 col-lg-3">
      <!-- header  -->
      <div class="d-flex mt-4 d-lg-none">
        <img [src]="checkout?.store?.logo" class="pic rounded border" />
        <!-- <div class="pic"></div> -->
        <div class="d-flex justify-content-center flex-fill flex-column ps-2">
          <div class="text-secondary fw-bolder fs-7 user-select-none">
            ORDER FROM
          </div>
          <div class="fs-5 text-dark fw-bolder user-select-none">
            {{ checkout?.store?.store_name }}
          </div>
          <div class="text-dark fw-bold user-select-none">
            {{ checkout?.store?.phone! }}
          </div>
        </div>
      </div>
    </div>

    <!-- right half -->
    <div class="col-12 col-lg-9 border-start">
      <div class="row mt-lg-4">
        <div class="col-12 col-lg-8 offset-lg-2 fw-500">
          <!-- order_type -->
          <div class="w-100 border-bottom pt-3 pb-3 d-inline-flex">
            <div class="col-3 d-inline-flex align-items-center pe-2">
              <div
                class="col-4 col-lg-3 fs-6 fw-bold d-flex align-items-center text-nowrap user-select-none"
              >
                Order type
              </div>
            </div>
            <div
              class="col-9 d-flex flex-column align-items-end align-items-lg-start"
            >
              <div
                class="btn-group"
                role="group"
                aria-label="Delivery or pickup button group"
              >
                <ng-container *ngIf="orderType?.['1']">
                  <input
                    type="radio"
                    class="btn-check"
                    id="deliveryRedioBtn"
                    name="order_type"
                    formControlName="order_type"
                    autocomplete="off"
                    [value]="'1'"
                    (change)="onDelivery($event)"
                  />
                  <label
                    class="btn btn-sm btn-outline-danger text-nowrap fw-500"
                    for="deliveryRedioBtn"
                    >Delivery</label
                  >
                </ng-container>
                <ng-container *ngIf="orderType?.['2']">
                  <input
                    type="radio"
                    class="btn-check"
                    name="order_type"
                    id="pickupRadioBtn"
                    formControlName="order_type"
                    autocomplete="off"
                    (change)="onPickup($event)"
                    [value]="'2'"
                  />
                  <label
                    class="btn btn-sm btn-outline-danger text-nowrap fw-500"
                    for="pickupRadioBtn"
                    >Pick up</label
                  ></ng-container
                >
              </div>
            </div>
          </div>
          <!-- time -->
          <div class="w-100 border-bottom pt-3 pb-3 d-inline-flex">
            <div
              class="flex-fill d-flex d-lg-inline-flex flex-column flex-lg-row"
            >
              <div class="col-12 col-lg-3 fs-6 fw-bold user-select-none pe-2">
                Time
              </div>
              <div
                class="col-12 col-lg-9 d-flex flex-column justify-content-center"
              >
                <a
                  class="user-select-none fs-6 w-100 text-danger d-inline-flex align-items-center"
                  (click)="changeTime()"
                >
                  <ng-container
                    *ngIf="
                      this.schedule_time?.value ||
                        checkout?.orderParams?.scheduleTime;
                      else asapTemplate
                    "
                  >
                    {{
                      this.schedule_time?.value ||
                        checkout?.orderParams?.scheduleTime | date: "short"
                    }}
                  </ng-container>
                  <ng-template #asapTemplate>
                    <ng-container *ngIf="!loading"
                      >ASAP (<ng-container
                        *ngIf="
                          orderScheduleValue?.note;
                          else defaultTimeTemplate
                        "
                      >
                        {{ orderScheduleValue?.note }}
                      </ng-container>
                      <ng-template #defaultTimeTemplate>20-30 min</ng-template>)
                      click to change.</ng-container
                    >
                  </ng-template>
                </a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-end">
              <a
                class="d-lg-none text-lg-start text-end text-danger user-select-none"
                (click)="changeTime()"
              >
                Change
              </a>
            </div>
          </div>
          <!-- name -->
          <div class="w-100 border-bottom pt-3 pb-3 d-inline-flex">
            <div
              class="flex-fill d-flex d-lg-inline-flex flex-column flex-lg-row"
            >
              <div class="col-12 col-lg-3 fs-6 fw-bold user-select-none pe-2">
                Name
              </div>
              <div
                class="col-12 col-lg-9 d-flex flex-column justify-content-center"
              >
                <!-- <ng-container
                  *ngIf="!name?.value && !loading; else nameTemplate"
                >
                  <a
                    class="user-select-none text-danger fs-6 w-100 d-inline-flex align-items-center"
                    (click)="changeName()"
                  >
                    Please click to change.</a
                  >
                </ng-container> -->
                <div class="input-group input-group-sm has-validation mt-2 checkout-input">
                  <input
                  style="margin-top: -.7rem;"
                  *ngIf="!loading"
                  type="text"
                  autocomplete="off"
                  formControlName="name"
                  class="form-control w-50"
                  [(ngModel)]="submitName"
                  maxlength="14"
                  aria-label="card-holder"
                  aria-describedby="card holder"
                />
                </div>
              </div>
              <ng-template #nameTemplate>
                <div class="fs-6">{{ name?.value }}</div>
              </ng-template>
            </div>
            <div class="d-flex align-items-center justify-content-end">
              <!-- <a
                class="d-lg-none text-lg-start text-end text-danger user-select-none"
                (click)="changeName()"
              >
                Change
              </a> -->
            </div>
          </div>
          <!-- address -->
          <div
            class="w-100 border-bottom pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0"
              >
                Address
              </div>
              <a
                class="text-lg-start text-end text-danger user-select-none"
                *ngIf="order_type?.value == '1'"
                (click)="addAddress()"
              >
                Add
              </a>
            </div>
            <ng-container
              *ngIf="order_type?.value == '2'; else deliveryAddressTemplate"
            >
              <div class="col-12 col-lg-9">
                <div class="w-100 mb-2 mt-2 mt-lg-0">
                  <div
                    class="alert-warning rounded shadow-sm text-center p-2 fw-500 user-select-none"
                  >
                    This is a pickup order!
                  </div>
                </div>
                <!-- <div class="w-100" *ngIf="order_type?.value == '2'">
                  <div
                    class="map bg-secondary rounded user-select-none"
                    *ngIf="
                      checkout?.store?.latitude && checkout?.store?.longitude
                    "
                  >
                    <google-map
                      *ngIf="apiLoaded"
                      height="100%"
                      width="100%"
                      [options]="googleMapOptions"
                    >
                      <ng-container *ngIf="marker.position">
                        <map-marker [position]="marker.position"></map-marker
                      ></ng-container>
                    </google-map>
                  </div>
                </div> -->
                <div class="w-100 pt-2 user-select-none fw-500 d-inline-flex">
                  <div
                    class="fs-6 flex-fill"
                    *ngIf="checkout?.store as storeRes"
                  >
                    {{ storeRes?.address + "," }}&nbsp;
                    <!-- <ng-container ngif></ng-container> -->

                    {{ storeRes?.city + "," | titlecase }}&nbsp;
                    {{ storeRes?.state + "," | uppercase }}&nbsp;
                    {{ storeRes?.zipcode }}
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #deliveryAddressTemplate>
              <div class="col-12 col-lg-9 d-inline-flex align-items-center">
                <div
                  class="w-100 pt-2 pt-lg-0 user-select-none fw-500 d-inline-flex"
                >
                  <div class="fs-6 flex-fill" *ngIf="addressString">
                    {{ addressString + ", " }}
                  </div>
                  <ng-container *ngIf="addressString; else addAddressTemplate">
                    <a
                      class="text-lg-start text-end text-danger user-select-none"
                      (click)="chooseAddress()"
                      >Change
                    </a>
                  </ng-container>
                  <ng-template #addAddressTemplate>
                    <ng-container
                      *ngIf="
                        !checkout?.deliveryInfo?.user_address_id && !loading
                      "
                    >
                      <div class="flex-fill text-danger fs-6">
                        <ng-container
                          *ngIf="
                            checkout?.deliveryInfo?.code == 101;
                            else addressTemplate
                          "
                        >
                          Please add another address!
                        </ng-container>
                        <ng-template #addressTemplate>
                          No address found! Pleass add address.
                        </ng-template>
                      </div>
                      <a
                        class="text-lg-start text-end text-danger user-select-none"
                        (click)="chooseAddress()"
                        >Change
                      </a></ng-container
                    >

                    <!-- <a
                      class="text-lg-start text-end text-danger user-select-none"
                      (click)="addAddress()"
                      >Add
                    </a> -->
                  </ng-template>
                </div>
              </div></ng-template
            >
          </div>
          <!-- phone number -->
          <div class="w-100 border-bottom pt-3 pb-3 d-inline-flex">
            <div
              class="flex-fill d-flex d-lg-inline-flex flex-column flex-lg-row"
            >
              <div class="col-12 col-lg-3 fs-6 fw-bold user-select-none pe-2">
                Phone
              </div>
              <div
                class="col-12 col-lg-9 d-flex flex-column justify-content-center"
              >
                <!-- <a
                  class="user-select-none w-100 text-dark d-inline-flex align-items-center"
                  (click)="changePhone()"
                >
                  <ng-container
                    *ngIf="!phone_number?.value; else userAddressTemplate"
                  >
                    <div class="text-danger fs-6" *ngIf="!loading">
                      Please click to change.
                    </div>
                  </ng-container>
                  <ng-template #userAddressTemplate>
                    {{ phone_number?.value }}</ng-template
                  >
                </a> -->
                <div
                class="input-group input-group-sm has-validation mt-2 checkout-input"
              >
                <input
                style="margin-top: -.7rem;"
                  *ngIf="!loading"
                  type="text"
                  autocomplete="off"
                  formControlName="phone_number"
                  class="form-control w-50"
                  [(ngModel)]="submitPhone"
                  aria-label="card-holder"
                  aria-describedby="card holder"
                  (input)="onInput($event)"
                />
              </div>
              </div>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-end">
              <a
                class="d-lg-none text-lg-start text-end text-danger user-select-none"
                (click)="changePhone()"
              >
                Change
              </a>
            </div> -->
          </div>

          <!-- note -->
          <div
            class="w-100 border-bottom pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0 pb-2 pb-lg-0"
              >
                Notes
              </div>
            </div>
            <ng-container>
              <div class="col-12 col-lg-9" *ngIf="!loading">
                <div class="input-group">
                  <textarea
                    formControlName="notes"
                    class="form-control"
                    aria-label="notes"
                  ></textarea>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- payment -->
          <div
            class="w-100 border-bottom pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0"
              >
                Payments
              </div>
            </div>
            <div class="col-12 col-lg-9">
              <!-- {{ paymentType | json }} -->
              <div
                class="btn-group mt-2 mt-lg-0 w-100"
                role="group"
                aria-label="Payments method button group"
              >
                <!-- [disabled]="feeLoading" -->

                <ng-container *ngIf="paymentType?.['1']">
                  <input
                    type="radio"
                    class="btn-check"
                    id="payCreditCard"
                    name="payment_type"
                    autocomplete="off"
                    formControlName="payment_type"
                    (change)="payByCreditCard($event)"
                    [value]="'1'"
                  />
                  <label
                    class="btn btn-sm btn-outline-danger text-nowrap fw-500"
                    for="payCreditCard"
                    >Pay by card</label
                  ></ng-container
                >
                <!-- [(ngModel)]="paymentTypeString" -->
                <!-- [ngModelOptions]="{ standalone: true }" -->
                <!-- [disabled]="feeLoading" -->

                <ng-container *ngIf="paymentType?.['2']">
                  <input
                    name="payment_type"
                    type="radio"
                    class="btn-check"
                    id="payInStore"
                    (change)="payInStore($event)"
                    formControlName="payment_type"
                    autocomplete="off"
                    [value]="'2'"
                  />
                  <label
                    class="btn btn-sm btn-outline-danger text-nowrap fw-500"
                    for="payInStore"
                    >Pay in store</label
                  ></ng-container
                >
              </div>
              <ng-container
                formGroupName="credit_card"
                *ngIf="payment_type?.value == '1'"
              >
                <div class="input-group input-group-sm has-validation mt-2">
                  <span class="input-group-text">Card number</span>
                  <!-- [class.is-invalid]="
                  tax_name!.touched && tax_name!.invalid
                "
                [class.is-valid]="tax_name!.touched && tax_name!.valid" -->
                  <!-- formControlName="name" -->
                  <input
                    #creditCardNumber
                    type="tel"
                    maxlength="19"
                    pattern="[0-9\s]{13,19}"
                    formControlName="card_number"
                    (keyup)="creditCardNumberSpacing()"
                    autocomplete="cc-number"
                    class="form-control"
                    placeholder="card number"
                    aria-label="card-number"
                    aria-describedby="card number"
                  />
                </div>
                <div class="input-group input-group-sm has-validation mt-2">
                  <span class="input-group-text">Name</span>
                  <input
                    type="text"
                    autocomplete="off"
                    formControlName="name"
                    class="form-control w-50"
                    placeholder="card holder"
                    aria-label="card-holder"
                    aria-describedby="card holder"
                  />
                </div>
                <div class="input-group input-group-sm has-validation mt-2">
                  <span class="input-group-text">Zipcode</span>
                  <input
                    type="tel"
                    autocomplete="off"
                    pattern="\d*"
                    class="form-control"
                    maxlength="5"
                    formControlName="zipcode"
                    placeholder="zipcode"
                    aria-label="zipcode"
                    aria-describedby="zipcode"
                  />
                  <span class="input-group-text">CVV</span>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    maxlength="4"
                    formControlName="cvv"
                    placeholder="CVV"
                    aria-label="cvv"
                    aria-describedby="cvv"
                  />
                </div>
                <div class="input-group input-group-sm has-validation mt-2">
                  <span class="input-group-text">Expiration date</span>
                  <input
                    #expirationDate
                    type="tel"
                    maxlength="5"
                    pattern="([0-9]{2}[/]?){2}"
                    class="form-control w-50"
                    placeholder="MM/YY"
                    formControlName="expiration_date"
                    (keyup)="expirationDateFormat()"
                    autocomplete="cc-exp"
                    aria-label="expired-date"
                    aria-describedby="expired date"
                  /></div
              ></ng-container>
            </div>
          </div>
          <!-- tip -->
          <div
            class="w-100 border-bottom pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0"
              >
                Tips
              </div>
            </div>
            <div class="col-12 col-lg-9" *ngIf="!loading">
              <div
                class="btn-group w-100 mt-2"
                role="group"
                aria-label="tips button radio"
              >
                <ng-container
                  *ngFor="let tip of checkout?.store?.tips; index as i"
                >
                  <input
                    type="radio"
                    class="btn-check"
                    name="tipsBtnRadio"
                    [id]="'tips-' + tip?.id + '-' + tip!.merchant_id"
                    [value]="tip.id"
                    autocomplete="off"
                    [(ngModel)]="tipsId"
                    [disabled]="feeLoading"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="selectTips($event, tip)"
                  />
                  <label
                    class="btn btn-outline-danger user-select-none fs-7 fw-500"
                    [for]="'tips-' + tip?.id + '-' + tip!.merchant_id"
                    >{{ tip?.value }}%</label
                  >
                </ng-container>
                <!-- value="0"
                [(ngModel)]="tipsAmount"
                [ngModelOptions]="{ standalone: true }" -->
                <input
                  type="radio"
                  class="btn-check"
                  id="customTips"
                  name="tipsBtnRadio"
                  autocomplete="off"
                  [disabled]="feeLoading"
                  [checked]="isCustomTips"
                  (click)="customTips($event)"
                />
                <label
                  class="btn btn-outline-danger user-select-none fs-7 fw-500"
                  for="customTips"
                  >custom</label
                >
              </div>

              <!-- <div class="w-100 mt-2" *ngIf="isCustomTips">
                <div class="input-group">
                  <span class="input-group-text user-select-none">$</span>
                  <input
                    type="text"
                    (keyup)=""
                    class="form-control"
                    placeholder="please input tips"
                    aria-label="tip amount"
                    formControlName="tips"
                    aria-describedby="custom"
                  />
                </div>
              </div> -->
              <ng-container *ngIf="isCustomTips">
                Custom tips: {{ checkout?.tips }}</ng-container
              >
            </div>
          </div>
          <!-- coupon  -->
          <div
            class="w-100 border-bottom pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0"
              >
                Coupon
              </div>
            </div>
            <div style="margin-top: -.7rem;" class="col-12 col-lg-9" *ngIf="!loading">
              <div class="input-group input-group-sm has-validation mt-2 checkout-input">
                <input class="form-control" [(ngModel)]="coupon_number" [ngModelOptions]="{standalone: true}"/>
                <button class="btn btn-sm btn-outline-danger text-nowrap fw-500" (click)="addCoupon()">Apply</button>
              </div>
              <ng-container
                *ngFor="let coupon of checkout?.coupons; let i = index"
              >
                <div class="fs-6">
                  {{ coupon.name }}
                  <button
                    class="btn text-danger"
                    (click)="deleteCoupon(coupon.name)"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </ng-container>
              <!-- <a class="fs-6 fw-500 text-danger mt-2" (click)="addCoupon()">
                Add Coupon Code</a
              > -->
            </div>
          </div>
          <!-- summary -->
          <div
            class="w-100 pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row border-bottom"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0 pb-3 pb-lg-0"
              >
                Summary
              </div>
              <ng-container *ngIf="!loading">
                <a
                  class="text-lg-start text-end text-danger user-select-none"
                  (click)="navigateToRestaurant()"
                >
                  Change
                </a></ng-container
              >
            </div>
            <div class="col-12 col-lg-9" *ngIf="!loading">
              <div
                [ngClass]="{
                  'border-bottom mb-3': i < items!.length - 1,
                  'text-danger': item.options.expired
                }"
                class="w-100"
                *ngFor="let item of items; let i = index"
              >
                <div class="w-100 fw-bold" *ngIf="item.options.expired">
                  Item expried!
                </div>
                <div class="w-100 d-inline-flex align-items-center">
                  <div class="fw-bold fs-6 user-select-none flex-fill">
                    {{ item?.name }}
                  </div>
                  <div class="fw-500 text-end overflow-hidden user-select-none">
                    {{ item?.quantity }} x ${{ item?.total?.toFixed(2) }}
                  </div>
                </div>
                <div
                  [ngClass]="{
                    'text-danger': item.options.expired
                  }"
                  class="w-100 d-flex flex-wrap align-items-center options fw-500 text-secondary text-wrap toe mb-3"
                >
                  <div
                    class="user-select-none d-flex flex-wrap text-nowrap toe"
                    *ngFor="
                      let modify of item?.options?.itemModifies;
                      index as i
                    "
                  >
                    <ng-container *ngIf="i > 0">&nbsp;•&nbsp;</ng-container>
                    {{ modify?.name }}
                  </div>

                  <ng-container
                    *ngFor="
                      let section of item?.options?.itemSections;
                      index as sectionIndex
                    "
                  >
                    <ng-container *ngIf="sectionIndex > 0">
                      &nbsp;•&nbsp;</ng-container
                    >
                    <ng-container *ngIf="section?.sectionItems">
                      <ng-container *ngIf="item?.options?.itemModifies?.length">
                        &nbsp;•&nbsp;
                      </ng-container>
                      <div
                        class="d-flex flex-wrap text-nowrap toe"
                        *ngFor="
                          let sectionItem of section?.sectionItems;
                          index as i
                        "
                      >
                        <ng-container *ngIf="i > 0">&nbsp;•&nbsp;</ng-container>
                        {{ sectionItem?.item?.name }}&nbsp;x&nbsp;{{
                          sectionItem?.quantity
                        }}
                      </div></ng-container
                    >
                    <ng-container *ngIf="section?.sectionModifiers">
                      <ng-container *ngIf="section?.sectionItems"
                        >&nbsp;•&nbsp;</ng-container
                      >
                      <ng-container
                        *ngIf="
                          item?.options?.itemModifies?.length &&
                          !section?.sectionItems
                        "
                      >
                        &nbsp;•&nbsp;</ng-container
                      >
                      <div
                        class="d-flex flex-wrap text-nowrap toe"
                        *ngFor="
                          let sectionModify of section?.sectionModifiers;
                          index as i
                        "
                      >
                        <ng-container *ngIf="i > 0">&nbsp;•&nbsp;</ng-container>
                        {{ sectionModify?.modify?.name }}&nbsp;x&nbsp;{{
                          sectionModify?.quantity
                        }}
                      </div>
                    </ng-container>
                  </ng-container>
                  <div
                    class="user-select-none d-inline-flex text-nowrap toe"
                    *ngIf="(item?.options)!.itemVariant!"
                  >
                    &nbsp;•&nbsp; ({{
                      (item?.options)!.itemVariant[0]?.name
                    }}
                    ${{ (item?.options)!.itemVariant[0]?.price }})
                  </div>
                  <div
                    class="user-select-none text-nowrap toe"
                    *ngIf="item?.options?.itemInstructions"
                  >
                    {{ item?.options?.itemInstructions }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
          *ngIf="is_utenstil == '1'"
          class="w-100 pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row border-bottom"
        >
          <div
            class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
          >
            <div
              style="min-width: 80px"
              class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0 pb-3 pb-lg-0"
            >
            Utensil
            </div>
            <!-- <ng-container *ngIf="!loading">
            <a
              class="text-lg-start text-end text-danger user-select-none"
              (click)="navigateToRestaurant()"
            >
              Change
            </a></ng-container
          > -->
          </div>
          <div class="col-12 col-lg-9" *ngIf="!loading">
        <div
          class="btn-group w-100"
          role="group"
          aria-label="Delivery or pickup button group"
        >
          <ng-container>
            <input
              type="radio"
              class="btn-check"
              id="tablesetNone"
              formControlName="tableWare"
              autocomplete="off"
              [value]="'None'"
              (change)="changeTableWare('None')"
            />
            <label
              class="btn btn-sm btn-outline-danger text-nowrap fw-500"
              for="tablesetNone"
              >None</label
            >
          </ng-container>
          <ng-container>
            <input
              type="radio"
              class="btn-check"
              id="Default"
              formControlName="tableWare"
              autocomplete="off"
              (change)="changeTableWare('Default')"
              [value]="'Default'"
            />
            <label
              class="btn btn-sm btn-outline-danger text-nowrap fw-500"
              for="Default"
              >Default</label
            ></ng-container
          >
          <ng-container>
            <input
              type="radio"
              class="btn-check"
              id="tableset1"
              formControlName="tableWare"
              autocomplete="off"
              (change)="changeTableWare('1 set')"
              [value]="'1 set'"
            />
            <label
              class="btn btn-sm btn-outline-danger text-nowrap fw-500"
              for="tableset1"
              >1 set</label
            ></ng-container
          >
          <ng-container>
            <input
              type="radio"
              class="btn-check"
              id="tableset2"
              formControlName="tableWare"
              autocomplete="off"
              (change)="changeTableWare('2 set')"
              [value]="'2 set'"
            />
            <label
              class="btn btn-sm btn-outline-danger text-nowrap fw-500"
              for="tableset2"
              >2 set</label
            ></ng-container
          >
          <ng-container>
            <input
              type="radio"
              class="btn-check"
              id="tableset3"
              formControlName="tableWare"
              autocomplete="off"
              (change)="changeTableWare('3 set')"
              [value]="'3 set'"
            />
            <label
              class="btn btn-sm btn-outline-danger text-nowrap fw-500"
              for="tableset3"
              >3 set</label
            ></ng-container
          >
          <ng-container>
            <input
              type="radio"
              class="btn-check"
              id="tableset4"
              formControlName="tableWare"
              autocomplete="off"
              (change)="changeTableWare('4 set')"
              [value]="'4 set'"
            />
            <label
              class="btn btn-sm btn-outline-danger text-nowrap fw-500"
              for="tableset4"
              >4 set</label
            ></ng-container
          >
          <ng-container>
            <input
              type="radio"
              class="btn-check"
              id="tablesetAdd"
              formControlName="tableWare"
              autocomplete="off"
              (change)="changeTableWare('Add notes for more')"
              [value]="'Add notes for more'"
            />
            <label
              class="btn btn-sm btn-outline-danger text-nowrap fw-500"
              for="tablesetAdd"
              >Add notes for more</label
            ></ng-container
          >
        </div>
            <!-- <div ngbDropdown placement="top-right">
              <button class="btn btn-warning" id="gfg"
                      ngbDropdownToggle>Click Here!</button>
              <div ngbDropdownMenu="gfg">
                <button ngbDropdownItem>GeeksforGeeks</button>
                <button ngbDropdownItem>Angular10</button>
                <button ngbDropdownItem>ng bootstrap</button>
              </div>
            </div> -->
          </div>
        </div>

          <!-- count  -->
          <div
            class="d-lg-none mb-2"
            [ngClass]="{ 'opacity-25': feeLoading || loading }"
          >
            <ng-container *ngIf="feeLoading">
              <div class="clearfix d-lg-none">
                <div class="fixed-receipt col-12 z-1099">
                  <div
                    *ngIf="!loading"
                    class="spinner-border spinner position-absolute"
                    role="status"
                  ></div>
                </div>
              </div>
            </ng-container>
            <div class="w-100 pt-3 pb-3 border-bottom fw-500">
              <div class="d-inline-flex w-100 pb-1">
                <div class="user-select-none fs-6 fw-500">Subtotal</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.subtotal | number: "1.2-2" }}
                </div>
              </div>
              <div
                class="d-inline-flex w-100 pb-1"
                *ngIf="order_type?.value == '1'"
              >
                <div class="user-select-none fs-6 fw-500">Delivery Fee</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.deliveryFees | number: "1.2-2" }}
                </div>
              </div>
              <div
                class="d-inline-flex w-100 pb-1"
                *ngIf="checkout?.coupons?.length"
              >
                <div class="user-select-none fs-6 fw-500">Coupon</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  $-{{ checkout?.couponsAmount | number: "1.2-2" }}
                </div>
              </div>
              <div class="d-inline-flex w-100 align-items-center">
                <div
                  class="user-select-none fs-6 fw-500 d-inline-flex align-items-center"
                >
                  Estimated Tax
                  <button
                    type="button"
                    class="btn p-1"
                    placement="bottom"
                    triggers="mouseenter:mouseleave"
                    [ngbPopover]="taxPopContent"
                    #tax="ngbPopover"
                    (click)="tax.open()"
                  >
                    <i class="ms-75 bi bi-info-circle"></i>
                  </button>
                </div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.tax | number: "1.2-2" }}
                </div>
              </div>
              <div class="d-inline-flex w-100 align-items-center">
                <div
                  class="user-select-none fs-6 fw-500 d-inline-flex align-items-center"
                >
                  Services Fee
                  <button
                    type="button"
                    class="btn p-1"
                    placement="bottom"
                    triggers="mouseenter:mouseleave"
                    [ngbPopover]="otherFeesPopContent"
                    #other="ngbPopover"
                    (click)="other.open()"
                  >
                    <i class="ms-75 bi bi-info-circle"></i>
                  </button>
                </div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.fees | number: "1.2-2" }}
                </div>
              </div>
            </div>
            <div class="w-100 pt-3 pb-3 border-bottom fw-500">
              <div class="d-inline-flex w-100">
                <div class="user-select-none fs-6 fw-500">Tip</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  <ng-container
                    *ngIf="
                      checkout?.tips && checkout?.tips !== '0.00';
                      else noTipsTemplate
                    "
                  >
                    ${{ checkout?.tips | number: "1.2-2" }}
                  </ng-container>
                  <ng-template #noTipsTemplate> No tips </ng-template>
                </div>
              </div>
            </div>
            <div class="w-100 pt-3 pb-3 fw-bold">
              <div class="d-inline-flex w-100">
                <div class="user-select-none fs-6">Total</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.total | number: "1.2-2" }}
                </div>
              </div>
            </div>
          </div>
          <!-- place order -->
          <div
            class="d-none d-lg-flex w-100 pt-4 pb-4 align-items-center justify-content-center"
          >
            <button
              class="col-12 col-lg-6 btn btn-danger shadow-sm fs-6"
              (click)="onPlaceOrder()"
              [disabled]="
                loading ||
                !placeOrderForm.valid ||
                feeLoading ||
                isCheckout ||
                checkout?.store?.closure
              "
            >
              Place Order
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div
  class="flex-column fixed-bottom container-fluid bg-light d-flex d-lg-none border-top pt-3 pb-3"
  #banners
>
  <div class="w-100 pb-2 d-lg-none" *ngIf="isCheckout">
    <div
      class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
    >
      Some items in the shopping cart expired!
    </div>
  </div>
  <div class="w-100 pb-2 d-lg-none" *ngFor="let coupon of checkout?.coupons">
    <div
      class="alert-warning rounded shadow-sm text-center fw-500 p-2 user-select-none"
    >
      Your saved money with {{ coupon.name }} code
    </div>
  </div>
  <div class="w-100 pb-2 d-lg-none" *ngIf="order_type?.value == 2">
    <div
      class="alert-warning rounded shadow-sm text-center fw-500 p-2 user-select-none"
    >
      This is a pickup order
    </div>
  </div>
  <div class="w-100 pb-2 d-lg-none" *ngIf="checkout?.store?.closure">
    <div
      class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
    >
      Store closed!
    </div>
  </div>
  <div class="w-100 pb-2 d-lg-none" *ngIf="order_type?.value == 1">
    <div
      class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
      *ngIf="delivery_address?.errors?.['required']"
    >
      Addresss information missing!
    </div>
  </div>
  <div class="w-100 pb-2 d-lg-none">
    <div
      class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
      *ngIf="phone_number?.errors?.['required']"
    >
      Phone number required!
    </div>
  </div>
  <div class="w-100 mb-2 d-lg-none" *ngIf="payment_type?.value == 1">
    <div
      class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
      *ngIf="card_number?.errors?.['required'] || cardName?.errors?.['required']|| expiration_date?.errors?.['required'] || zipcode?.errors?.['required'] || cvv?.errors?.['required']"
    >
      Credit card information missing!
    </div>
  </div>
  <div class="w-100 d-flex align-items-center justify-content-center pt-2 pb-2">
    <button
      class="col-12 col-lg-6 btn btn-danger shadow-sm fs-6"
      (click)="onPlaceOrder()"
      [disabled]="
        loading ||
        !placeOrderForm.valid ||
        feeLoading ||
        isCheckout ||
        checkout?.store?.closure
      "
    >
      Place Order
    </button>
  </div>
</div>
<ng-template #taxPopContent>
  <div class="d-flex flex-column">
    <ng-container *ngFor="let tax of checkout?.store?.tax">
      <div class="d-inline-flex fw-500">
        <div class="text-nowrap">{{ tax.name }}:</div>
        <div class="ms-2">{{ tax.tax | number: "1.2-2" }}%</div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #otherFeesPopContent>
  <div class="d-flex flex-column">
    <!-- <ng-container *ngFor="let cdp of checkout.cart.cdpFees.list">
      <div class="d-inline-flex fw-500">
        <div class="text-nowrap">{{ cdp.name }}:</div>
        <div class="ms-2">{{ cdp.value | number: "1.2-2" }}%</div>
      </div>
    </ng-container>
    <ng-container *ngFor="let customFee of checkout.cart.customFees.list">
      <div class="d-inline-flex fw-500">
        <div class="text-nowrap">{{ customFee.name }}:</div>
        <div class="ms-2">{{ customFee.value | number: "1.2-2" }}%</div>
      </div>
    </ng-container> -->
  </div>
</ng-template>
