<div class="modal-header">
  <h5 class="modal-title" style="color: red;">Error</h5>
  <button
    type="button"
    class="close btn"
    aria-label="Close"
    (click)="activeModal.close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="color: red; font-size: medium; text-align: center;">
  {{ data }}
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close(false)"
  >
    Close
  </button>
</div>
