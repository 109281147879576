<div class="modal-header">
  <h5 class="modal-title">Choose address</h5>
  <button
    type="button"
    class="close btn"
    aria-label="Close"
    (click)="activeModal.close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
    <div class="form-check" *ngFor="let a of addresses; let i = index">
      <ng-container *ngIf="a.id">
        <input
          class="form-check-input"
          name="changeAddresssRadio"
          type="radio"
          id="{{ a.id + i }}"
          (change)="onCheck($event, a.id)"
          [checked]="a.id == addressId"
        />
        <label class="form-check-label fw-500" for="{{ a.id + i }}">
          {{ a?.address + ", " }}
          <ng-container *ngIf="a?.optional">
            {{ a?.optional + ", " }}
          </ng-container>
          {{ a?.city + ", " + a?.state + ", " + a?.zipcode }}
        </label>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-inline-flex">
    <button type="button" class="btn btn-danger" (click)="addAddress()">
      Add
    </button>
  </div>
  <div class="d-inline-flex flex-fill justify-content-end">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.close(false)"
    >
      Close
    </button>
    <!-- || !addressChecked -->
    <button
      type="submit"
      class="btn btn-danger ms-2"
      [disabled]=""
      (click)="onAddress()"
    >
      <div *ngIf="!loading" class="user-select-none">Select</div>
    </button>
  </div>
</div>
