import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';

@Component({
  selector: 'payment-error-modal',
  templateUrl: './paymenterror-modal.component.html',
  styleUrls: ['./paymenterror-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentErrorComponent implements OnInit {
  @Input() public data:any;
  constructor(
    public activeModal: NgbActiveModal,
  ) {}
  ngOnInit(): void {}
}
