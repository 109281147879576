<uo-header [length]="itemLength"></uo-header>
<div class="min-vh-100" #storeContent>
  <!-- spinner -->
  <div class="clearfix" *ngIf="loading">
    <div
      class="spinner-wrap fixed-top d-flex align-items-center justify-content-center"
    >
      <div class="spinner-border spinner" role="status"></div>
    </div>
  </div>
  <div class="fill-up mt-xl-4"></div>
  <!-- restaurant information conponents -->
  <ng-container>
    <uo-restaurant-info (heightEvent)="height = $event"></uo-restaurant-info>
    <uo-menu
      [height]="height"
      [contentHeight]="contentView.nativeElement.offsetHeight"
    ></uo-menu>
  </ng-container>
  <!-- menu categories -->
</div>
<uo-full-footer></uo-full-footer>
