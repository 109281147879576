<header #info class="restaurant-wrap container-xl shadow-sm border">
  <div class="restaurant-pictures row">
    <!-- restaurant background picture -->
    <span
      *ngIf="storeData?.banner"
      class="restaurant-background-picture col-12 gx-0 shadow-sm user-select-none"
      [ngStyle]="{
        'background': 'url(' + storeData?.banner + ') 0% 0% / cover no-repeat',
        'background-position-x': 'center'
      }"
    >
    </span>
    <!-- restaurant logo picture -->
    <div
      class="restaurant-logo-wrap rounded border shadow align-items-center"
      *ngIf="storeData?.logo"
    >
      <!-- ; else defaultLogo -->
      <picture class="restaurant-logo">
        <source [srcset]="storeData?.logo" />
        <img [src]="storeData?.logo" class="img-fluid" />
      </picture>
      <!-- <ng-template #defaultLogo>
        <picture class="restaurant-logo">
          <source
            sizes=""
            srcset="../../../../../../assets/images/example/up-logo.png"
          />
          <img
            src="../../../../../../assets/images/example/up-logo.png"
            class="img-fluid"
            alt=""
          />
        </picture>
      </ng-template> -->
    </div>
  </div>
  <div class="row p-3">
    <div class="col-12 col-sm-8 col-lg-9 gx-0" *ngIf="storeData">
      <div class="col-12 fs-1 text-dark fw-bolder user-select-none">
        {{ storeData?.store_name }}
      </div>
      <ng-container *ngIf="storeData.closure">
        <div class="text-danger fs-5 fw-bold">This store is offline.</div>
      </ng-container>
      <div
        class="d-flex flex-column d-lg-inline-flex flex-lg-row flex-wrap text-secondary fw-bold"
      >
        <div class="d-inline-flex">
          <a
            class="text-dark"
            placement="bottom"
            ngbTooltip="Find store"
            [href]="
              'http://maps.google.com/?q=' +
              storeData?.address +
              ',' +
              storeData?.city +
              ',' +
              storeData?.state +
              ',' +
              storeData?.zipcode
            "
            target="_blank"
          >
            {{ storeData?.address + "," }}
            <ng-container *ngIf="storeData?.optional"
              >{{ storeData?.optional + "," }}
            </ng-container>
            {{ storeData?.city + "," | titlecase }}
            {{ storeData?.state + "," | titlecase }}
            {{ storeData?.zipcode }}</a
          >

          <!-- • Mexican, Deli, Chinese • 3.8⭐ • $$ -->
        </div>
        <ng-container>
          <ng-container
            *ngFor="
              let businessHour of storeData?.business_hour;
              let businessHourIndex = index
            "
          >
            <ng-container *ngIf="parseDay(businessHour?.scope!)">
              <div class="d-none d-lg-block">&nbsp;•&nbsp;</div>
              {{ businessHour?.open_hour }}&nbsp;-&nbsp;{{
                businessHour?.close_hour
              }}</ng-container
            >
          </ng-container>
          <ng-container *ngIf="storeData?.phone!">
            <span class="d-none d-lg-block ms-1 me-1">&nbsp;•&nbsp;</span>
            <a
              [href]="'tel:' + storeData?.phone!"
              class="text-dark"
              placement="bottom"
              ngbTooltip="Call store"
              ><div class="d-inline-flex">
                {{ storeData?.phone! | phone }}
              </div>
            </a></ng-container
          >
          <!-- <ng-container>
            <span class="d-none d-lg-block ms-1 me-1">&nbsp;•&nbsp;</span>
            <a
              class="text-dark"
              (click)="anchorToFooter('storeHourAnckor')"
              placement="bottom"
              ngbTooltip="See store full schedule"
            >
              About
            </a></ng-container -->
        </ng-container>
      </div>
    </div>
    <div
      class="col-12 col-sm-4 col-lg-3 gx-0 mb-1 mb-sm-0 mt-1 mt-sm-0 d-sm-flex align-items-sm-center justify-content-sm-end"
      *ngIf="storeData?.is_catering === 1"
    >
      <button
        type="button"
        class="btn btn-md border rounded fw-bold text-nowrap text-danger"
        (click)="openCatering()"
      >
        Catering Form
      </button>
    </div>
  </div>
  <!-- <div class="alert alert-danger text-center user-select-none h6" role="alert">
    This is a pick-up order
  </div>
  <div class="alert alert-warning text-center" role="alert">
    <p class="h6 user-select-none"> You're out of range</p>
    <p class="user-select-none">This restaurant can't be delivered to Flushing, NY 11355, USA.
    </p>
  </div> -->
</header>
