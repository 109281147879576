<div class="modal-header">
  <h5 class="modal-title" id="exampleModalToggleLabel">Time</h5>
  <button
    type="button"
    class="close btn"
    aria-label="Close"
    (click)="activeModal.close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <ng-container *ngIf="timeStr; else timeTemplate">
        <div class="col-12 fs-6 fw-bold mb-3">
          You select {{ timeStr | date: "M/d/yy, h:mm a" }}
        </div></ng-container
      >
      <ng-template #timeTemplate>
        <div class="col-12 fs-6 fw-bold mb-3">
          Select a time up to 3 days in advance.
        </div>
      </ng-template>
      <div class="d-flex align-items-center justify-content-center w-100">
        <div
          class="btn-group w-100"
          role="group"
          aria-label="Basic radio toggle button group "
        >
          <input
            type="radio"
            class="btn-check"
            name="btntimeradio"
            id="btnASAP"
            autocomplete="off"
            value="ASAP"
            (click)="timeStr = ''"
            [(ngModel)]="timeBtnChecked"
            [disabled]="loading"
            checked
          />
          <label class="btn btn-outline-danger fw-500" for="btnASAP"
            >ASAP</label
          >

          <input
            type="radio"
            class="btn-check"
            name="btntimeradio"
            id="btnLater"
            autocomplete="off"
            value="Later"
            (click)="later()"
            [disabled]="loading"
            [(ngModel)]="timeBtnChecked"
          />
          <label class="btn btn-outline-danger fw-500" for="btnLater"
            >Later</label
          >

          <!--
          <input type="radio" class="btn-check" name="btntimeradio" id="btnLater" autocomplete="off">
          <label class="btn btn-outline-danger" for="btnLater">Later</label> -->
        </div>
      </div>
      <div class="mt-3 fw-500" *ngIf="timeBtnChecked == 'ASAP'">
        <div class="col-12 text-center">{{ restaurantName }}</div>
        <div class="col-12 text-center">
          eta:
          <ng-container
            *ngIf="orderScheduleValue?.note; else defaultTimeTemplate"
          >
            {{ orderScheduleValue?.note }}
          </ng-container>
          <ng-template #defaultTimeTemplate>20-30 min</ng-template>
        </div>
        <div class="col-12 text-center text-secondary">
          +20 min for orders over $150
        </div>
      </div>
      <!-- <div class="Today gx-0 mt-3">
        <select class="form-select" aria-label="Default select example">
          <option selected value="0">time 1</option>
          <option value="1">time 2</option>
          <option value="2">time 3</option>
          <option value="3">time 4</option>
        </select>
      </div> -->
      <ng-container *ngIf="timeBtnChecked == 'Later'">
        <ng-template #loadingTemplate>
          <div
            class="pt-3 pb-3 w-100 h-100 d-flex align-items-center justify-content-center"
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </ng-template>
        <ng-container *ngIf="!loading; else loadingTemplate">
          <div class="w-100 mt-3">
            <div class="d-flex align-items-center justify-content-center w-100">
              <div
                class="btn-group w-100"
                role="group"
                aria-label="time select btn group"
              >
                <input
                  type="radio"
                  class="btn-check"
                  name="dateBtn"
                  [value]="0"
                  id="day0"
                  autocomplete="off"
                  [(ngModel)]="selectIndex"
                  (ngModelChange)="timeStr = ''"
                  checked
                />
                <label
                  class="btn btn-sm btn-outline-secondary fw-500"
                  for="day0"
                  >Today</label
                >

                <input
                  type="radio"
                  class="btn-check"
                  name="dateBtn"
                  [value]="1"
                  id="day1"
                  [(ngModel)]="selectIndex"
                  (ngModelChange)="timeStr = ''"
                  autocomplete="off"
                />
                <label
                  class="btn btn-sm btn-outline-secondary fw-500"
                  for="day1"
                  >Tomorrow</label
                >

                <input
                  type="radio"
                  class="btn-check"
                  name="dateBtn"
                  [value]="2"
                  id="day2"
                  [(ngModel)]="selectIndex"
                  (ngModelChange)="timeStr = ''"
                  autocomplete="off"
                />
                <label
                  class="btn btn-sm btn-outline-secondary fw-500"
                  for="day2"
                  >Day 2</label
                >
              </div>
            </div>
          </div>
          <div
            class="col-12 mt-3 d-flex align-items-center justify-content-center"
          >
            <select
              class="form-select form-select-sm"
              [(ngModel)]="timeStr"
              [disabled]="timeList[selectIndex]?.length == 0"
              aria-label="time select"
            >
              <option [value]="" [disabled]="true">Please select time</option>
              <option
                [value]="time"
                *ngFor="let time of timeList[selectIndex]; let i = index"
              >
                {{ time | date: "M/d/yy, h:mm a" }}
              </option>
            </select>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close(false)"
  >
    Close
  </button>
  <button class="btn btn-danger" (click)="saveTime()">Save</button>
</div>
