import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './pages/pages.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/apis/interceptor.service';
import { MessageModule } from './share/components/message/message.module';
import { StateModule } from './state/store.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StartService } from './services/start.service';
export function StartupServiceFactory(startService: StartService): Function {
  return () => startService.load();
}
const startServiceProviders = [
  StartService,
  {
    provide: APP_INITIALIZER,useFactory: StartupServiceFactory,deps: [StartService],multi: true
  },
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
];
@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    MessageModule,
    PagesModule,
    StateModule,
    NgbModule,
    AppRoutingModule,
  ],
  exports: [PagesModule, MessageModule, AppRoutingModule],
  providers: [
   ...startServiceProviders
  ],
})
export class CoreModule {
  constructor(@SkipSelf() @Optional() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule只能被AppModule引入');
    }
  }
}
