<nav
  class="shadow-sm navbar navbar-expand-lg navbar-light bg-light fixed-top border-bottom"
>
  <div class="container-fluid d-flex justify-content-center">
    <!-- <a
      class="navbar-brand m-0 text-danger user-select-none fw-bold"
      href="http://www.uorder.io"
      >uOrder
    </a> -->
    <a
      routerLink=""
      class="navbar-brand m-0 text-danger user-select-none fw-bold"
      >uOrder
    </a>
  </div>
</nav>
